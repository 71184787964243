import {
  AccountType,
  CompleteSetupResponse,
  onboardingGateway,
} from '@msl/user-gateway-sdk';

import { AnalyticsData, OnboardingData } from '../types';

/**
 * submit form of the first onboarding step
 *
 * @returns gateway response
 */
export const tellUsAboutYouFormSubmit = async (
  data: OnboardingData
): Promise<AnalyticsData> => {
  try {
    await onboardingGateway.updateBasicInfo(data.userBasicInfo);
    const userFullName = data.userBasicInfo.userName?.split(' ');
    const companySize =
      data.userBasicInfo.companySize === 'xxlarge'
        ? 'xlarge'
        : data.userBasicInfo.companySize || '';
    return {
      eventName: 'onboarding_sql_step_completed',
      eventAttributes: {
        company_size: companySize,
        company_name: data.userBasicInfo.companyName || '',
      },
      cioEventName: 'company_data_update',
      cioEventAttributes: {
        company_name: data.userBasicInfo.companyName || '',
        company_size: companySize,
        title: data.userBasicInfo.userTitle || '',
        full_name: data.userBasicInfo.userName || '',
        first_name: userFullName?.shift() || '',
        last_name: userFullName?.join(' ') || '',
      },
    };
  } catch (e) {
    console.error('tell us about you error.', e);
    return {};
  }
};

/**
 * submit form of the second onboarding step
 *
 * @returns gateway response
 */
export const selectEmailPlatformFormSubmit = async (
  data: OnboardingData
): Promise<AnalyticsData> => {
  try {
    await onboardingGateway.setAccountType({
      accountType: data.accountTypeInfo.accountType,
      companyIndustry: data.accountTypeInfo.companyIndustry,
    });
    if (!data.calendlyUrl) {
      await onboardingGateway.setWorkspace(data.workspaceInfo);
    }
    return {
      eventName: 'onboarding_use_case_completed',
      eventAttributes: {
        use_case:
          data.accountTypeInfo.accountType == AccountType.TEAMS
            ? 'company'
            : 'solo',
        industry: data.accountTypeInfo.companyIndustry || '',
      },
      cioEventName: 'company_data_update',
      cioEventAttributes: {
        company_indutry: data.accountTypeInfo.companyIndustry || '',
      },
    };
  } catch (e) {
    console.error('set account type error.', e);
    return {};
  }
};

/**
 * submit form of the third onboarding step
 *
 * @returns gateway response
 */
export const bookADemoOrSkipFormSubmit = async (
  data: OnboardingData
): Promise<AnalyticsData> => {
  try {
    await onboardingGateway.setWorkspace(data.workspaceInfo);
    return {
      eventName: `onboarding_completed_user_${data.demoScheduled ? 'booked_a' : 'skipped'}_demo`,
      eventAttributes: {
        demo_scheduled: `${data.demoScheduled}`,
      },
    };
  } catch (e) {
    console.error('set workspace error.', e);
    return {};
  }
};

/**
 * complete setup
 *
 * @returns gateway response
 */
export const completeSetup = async (): Promise<CompleteSetupResponse> => {
  try {
    return await onboardingGateway.completeSetup({});
  } catch (e) {
    console.error('complete setup error.', e);
    return {};
  }
};
