<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';

import { WsInput } from '@mfl/common-components';

import onboardingStrings from '../../onboarding-wrapper.strings';
import radioButton from '../radio-button.vue';
import { RadioButtonOption } from '../../onboarding-wrapper-types';
import { OnboardingData } from '../../../types';
import { isEmptyString, sendAnalytics } from '../../../utils';

const emit = defineEmits(['enableToContinue']);

const onboardingData = defineModel<OnboardingData>({
  required: true,
});

const companySizeOptions = ref<RadioButtonOption[]>([
  { value: 'small', label: onboardingStrings.companySizeOptionSmall },
  { value: 'medium', label: onboardingStrings.companySizeOptionMedium },
  { value: 'large', label: onboardingStrings.companySizeOptionLarge },
  { value: 'xlarge', label: onboardingStrings.companySizeOptionXlarge },
  { value: 'xxlarge', label: onboardingStrings.companySizeOptionXXlarge },
]);

const isFormValid = computed(() => {
  return (
    !isEmptyString(onboardingData.value.userBasicInfo.userName || '') &&
    !isEmptyString(onboardingData.value.userBasicInfo.userMobile || '') &&
    !isEmptyString(onboardingData.value.userBasicInfo.companySize || '') &&
    !isEmptyString(onboardingData.value.userBasicInfo.companyName || '')
  );
});

const userSelectedCompanySize = ref(false);
const userNameInput = ref(false);
const userTitleInput = ref(false);
const userMobileInput = ref(false);
const userCompanyNameInput = ref(false);

watch(
  () => onboardingData.value.userBasicInfo.userName,
  (newVal) => {
    if (newVal && !userNameInput.value) {
      sendAnalytics({ eventName: 'user_started_typing_name' });
      userNameInput.value = true;
    }
  }
);

watch(
  () => onboardingData.value.userBasicInfo.userTitle,
  (newVal) => {
    if (newVal && !userTitleInput.value) {
      sendAnalytics({ eventName: 'user_started_typing_title' });
      userTitleInput.value = true;
    }
  }
);

watch(
  () => onboardingData.value.userBasicInfo.userMobile,
  (newVal) => {
    if (newVal && !userMobileInput.value) {
      sendAnalytics({ eventName: 'user_started_typing_mobile' });
      userMobileInput.value = true;
    }
  }
);

watch(
  () => onboardingData.value.userBasicInfo.companyName,
  (newVal) => {
    if (newVal && !userCompanyNameInput.value) {
      sendAnalytics({ eventName: 'user_started_typing_company_name' });
      userCompanyNameInput.value = true;
    }
  }
);

watch(
  () => onboardingData.value.userBasicInfo.companySize,
  (newVal) => {
    if (newVal && !userSelectedCompanySize.value) {
      sendAnalytics({ eventName: 'user_selected_company_size' });
      userSelectedCompanySize.value = true;
    }
  }
);

onMounted(() => {
  sendAnalytics({ eventName: 'onboarding_sql_step_view' });
  emit('enableToContinue', isFormValid.value);
});

watch(isFormValid, (val) => {
  emit('enableToContinue', val);
});

function userNameValidation(val: string) {
  if (isEmptyString(val)) return onboardingStrings.userNameRequired;

  return true;
}

function userMobileValidation(val: string) {
  if (isEmptyString(val)) return onboardingStrings.userMobileRequired;

  return true;
}

function companyNameValidation(val: string) {
  if (isEmptyString(val)) return onboardingStrings.companyNameRequired;

  return true;
}
</script>

<template>
  <div class="text-3xl font-semibold form-header">
    {{ onboardingStrings.tellUsAboutYourFormHeader }}
  </div>
  <WsInput
    v-model.trim="onboardingData.userBasicInfo.userName"
    aid="ONBOARDING_USER_NAME"
    size="lg"
    :label="onboardingStrings.userNameLabel"
    :rules="[userNameValidation]"
  ></WsInput>
  <WsInput
    v-model.trim="onboardingData.userBasicInfo.userTitle"
    aid="ONBOARDING_USER_TITLE"
    size="lg"
    :label="onboardingStrings.userTitleLabel"
  ></WsInput>
  <WsInput
    v-model.trim="onboardingData.userBasicInfo.userMobile"
    aid="ONBOARDING_USER_MOBILE"
    size="lg"
    :label="onboardingStrings.userMobileLabel"
    :rules="[userMobileValidation]"
  ></WsInput>
  <WsInput
    v-model.trim="onboardingData.userBasicInfo.companyName"
    aid="ONBOARDING_USER_COMPANY_NAME"
    size="lg"
    :label="onboardingStrings.companyNameLabel"
    :rules="[companyNameValidation]"
  ></WsInput>
  <div class="text-3xl font-semibold form-header">
    {{ onboardingStrings.tellUsAboutYourFormCompanySizeHeader }}
  </div>
  <radio-button
    v-model="onboardingData.userBasicInfo.companySize"
    :style="{ flexWrap: 'unset' }"
    :options="companySizeOptions"
    option-width="115px"
    aid="ONBOARDING_USER_COMPANY_SIZE"
  ></radio-button>
</template>
<style lang="css" scoped>
.form-header {
  margin-top: 60px;
  margin-bottom: 24px;
}
</style>
