import { openDialog } from '@mfl/common-components';
import calendlyDialog from './calendly-dialog.vue';

import strings from './calendly.strings';

export type CalendlyDialogInput = {
  calendlyUrl: string;
  eventName: string;
};

export type CalendlyDialogOutput = {
  demoScheduled: boolean;
};

export const openInviteEmailPreviewDialog = async (
  input: CalendlyDialogInput
): Promise<CalendlyDialogOutput | undefined> => {
  const res = await openDialog<CalendlyDialogInput, CalendlyDialogOutput>({
    aid: 'DEMO_SESSION_DIALOG',
    headerText: strings.demoSessionDialogHeader,
    component: calendlyDialog,
    input,
  });
  return res;
};
