<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from 'vue';
import {
  EmailPlatform,
  onboardingGateway,
  OnboardingStatus,
} from '@msl/user-gateway-sdk';
import { auth4Sdks, onSetupCompleted } from '@mfl/platform-shell';
import { loadingDone } from '@mfl/framework';
import { WsButton } from '@mfl/common-components';
import { openInviteEmailPreviewDialog } from '../components/calendly/calendly-dialog';
import { errorNotification, sendAnalytics, setOnboardingData } from '../utils';
import { OnboardingData } from '../types';
import { AccountSetupItem } from './account-setup-types';
import SetupItemList from './setup-item-list.vue';
import strings from './account-setup.strings';
import { setupAccountSteps } from './account-setup-consts';

const isLoadingDone = ref(true);

const onboardingData = ref<OnboardingData>({
  userBasicInfo: {},
  accountTypeInfo: {},
  workspaceInfo: {},
  demoSession: undefined,
  demoScheduled: false,
  userEmail: '',
  userCountry: '',
  calendlyUrl: '',
  userRole: '',
});

const accountSetupData = ref<AccountSetupItem[]>([]);

const isSuccessState = computed(() => {
  return (
    accountSetupData.value.length &&
    accountSetupData.value.length ===
      accountSetupData.value.filter((v) => v.isCompleted).length
  );
});

const eventAttributes = computed(() => {
  return {
    tasks_completed: `${accountSetupData.value.filter((v) => v.isCompleted).length}`,
    total_tasks: `${accountSetupData.value.length}`,
    connector_type: getEmailPlatformCode(),
    role: onboardingData.value.userRole,
  };
});

const setAccountSetupData = (
  onboardingStatus: OnboardingStatus
): AccountSetupItem[] => {
  let firstExpand: boolean = false;
  return setupAccountSteps.map(function (v) {
    const isCompleted = onboardingStatus[v.id as keyof OnboardingStatus];
    const data: AccountSetupItem = {
      ...v,
      ...{
        isCompleted: isCompleted,
        isExpanded: !firstExpand && !isCompleted,
      },
    };
    if (!firstExpand && !isCompleted) {
      firstExpand = true;
    }
    return data;
  });
};

watch(isSuccessState, async (val) => {
  if (val) {
    await onboardingGateway.completeSetup({});
    onSetupCompleted();
  }
});

onBeforeMount(async () => {
  try {
    await onboardingGateway.init(auth4Sdks());
    await initSetupData();
    await sendAnalytics({
      eventName: 'setup_page_view',
      eventAttributes: eventAttributes.value,
    });
  } catch (e) {
    console.error('get onboarding status failed', e);
    errorNotification('SETUP_ERROR_TOAST');
  } finally {
    isLoadingDone.value = true;
    loadingDone();
  }
});

async function initSetupData(): Promise<void> {
  const resp = await onboardingGateway.getBasicInfo({});
  accountSetupData.value = setAccountSetupData(resp.onboardingStatus || {});
  setOnboardingData(onboardingData.value, resp, 'setup_page');
}

function getEmailPlatformCode(): string {
  if (onboardingData.value.workspaceInfo.emailPlatform === undefined) return '';

  return EmailPlatform[onboardingData.value.workspaceInfo.emailPlatform];
}

function onCompleteStage(option: AccountSetupItem) {
  option.isCompleted = true;
}

function onExpandOption(option: AccountSetupItem) {
  if (option.isExpanded) {
    option.isExpanded = false;
  } else {
    const currentlyExpanded = accountSetupData.value.find((v) => v.isExpanded);
    if (currentlyExpanded) currentlyExpanded.isExpanded = false;
    option.isExpanded = true;
  }
}
</script>

<template>
  <div v-if="isLoadingDone && isSuccessState" class="setup-completed">
    <div class="form">
      <div class="text-2xl font-bold">{{ strings.title }}</div>
      <div class="descr">
        <img
          src="/account-setup/setup-completed.png"
          aid="SIGNUP_FLOW_SUCCESS_STATE"
        />
        <div class="text-xl font-bold">{{ strings.setupCompletedDescr }}</div>
      </div>
    </div>
  </div>
  <div v-else-if="isLoadingDone" class="account-setup">
    <div class="form">
      <div class="text-2xl font-bold">{{ strings.title }}</div>
      <div class="descr text-md font-semibold">{{ strings.description }}</div>

      <SetupItemList
        v-if="accountSetupData.length"
        :account-setup-data="accountSetupData"
        :user-role="onboardingData.userRole"
        :email-platform="getEmailPlatformCode()"
        @complete-stage="onCompleteStage"
        @expand-option="onExpandOption"
      >
      </SetupItemList>
    </div>
    <div class="margin">
      <div v-if="onboardingData.calendlyUrl" class="schedule-demo">
        <div class="text-lg font-bold">{{ strings.scheduleDemoTitle }}</div>
        <div class="text-md font-semibold">{{ strings.scheduleDemoDescr }}</div>
        <WsButton
          :label="strings.scheduleDemoBtn"
          class="schedule-demo-btn"
          variant="outlined"
          size="sm"
          aid="SETUP_ACCOUNT_SCHEDULE_DEMP_BTN"
          @click="
            openInviteEmailPreviewDialog({
              calendlyUrl: onboardingData.calendlyUrl,
              eventName: 'setup_page_book_a_demo_clicked',
            })
          "
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.account-setup {
  overflow: auto;
  height: 100vh;
  display: flex;
  justify-content: space-between;

  .form {
    padding: 24px;
    width: 100%;
  }

  .descr {
    padding-top: 24px;
    padding-bottom: 14px;
  }

  .margin {
    width: 25vw;
    min-width: 300px;
    border-left: 1px solid #e0e0e0;
    padding: 24px;
  }

  .schedule-demo {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 6px;
    border: 1px solid var(--Gray-200, #e0e0e0);
    background: var(--Primary-50, #f4f6f8);
  }

  .schedule-demo-btn {
    margin-top: 16px;
  }
}

.setup-completed {
  overflow: auto;
  height: 80vh;
  .form {
    padding: 24px;
  }

  .descr {
    text-align: center;
    width: 391px;
    margin: 100px auto;
  }

  img {
    margin-bottom: 30px;
  }
}
</style>
