<script setup lang="ts">
import AccountSetup from './account-setup/account-setup.vue';
import OnboardingWrapper from './onboarding-wrapper/onboarding-wrapper.vue';

const isOnboarding = window.location.pathname.includes('/onboarding');
</script>

<template>
  <OnboardingWrapper v-if="isOnboarding" />
  <AccountSetup v-else />
</template>

<style lang="css" scoped></style>
