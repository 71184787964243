export default {
  title: 'Setup',
  description: 'Complete the following to get your signatures up and running.',
  learnMore: 'Learn more',
  scheduleDemoTitle: 'Need help setting up?',
  scheduleDemoDescr: 'Book a live session with our product experts',
  scheduleDemoBtn: 'Book a session',
  setupCompletedDescr: 'Congratulations. You are all setup!',
  addEmployeeTitle: 'Add employees',
  addEmployeeDescr:
    'Add to WiseStamp all the employees you want to have email signatures. You can add employees manually, using a CSV file, or by integrating with a third-party directory like Google Workspace or Microsoft Entra.',
  addEmployeeActionBtn: 'Add employees',
  setConfigurationTitle: 'Set up a deployment method',
  setConfigurationDescr:
    "Set up a deployment method to ensure your signatures appear in users' emails. WiseStamp offers both client-side and server-side solutions, making it easy to deploy your signatures across your organization.",
  setConfigurationActionBtn: 'Integrate with your service',
  signatureUpdateTitle: 'Design signature template for your company',
  signatureUpdateDescr:
    'Create a signature template for your organization. This template serves as the master design, helping maintain a uniform and professional look across your organization. You can also create additional templates if different designs are needed for various departments or roles.',
  signatureUpdateActionBtn: 'Design signature template',
  activateEmployeeTitle: 'Go live',
  activateEmployeeDescr:
    'The final step is to activate employees so their email signatures are applied.\
     Before going live, ensure all previous steps are complete. Once activated, signatures will be applied based on your chosen deployment method, maintaining a consistent, professional look across your organization.',
  activateEmployeeActionBtn: 'Select employees',
  actionDisabledAlert:
    "You don't have the required permissions. Please contact your admin for assistance.",
  manualInstallationBtn: 'Proceed without integration',
  manualInstallationConfirmHeader: 'Proceed without integration',
  manualInstallationConfirmDescr:
    'If you continue without integration, you’ll need to send the\nEmployee Hub link to employees so they can install their signatures.',
  manualInstallationConfirmCancel: 'Cancel',
  manualInstallationConfirmOk: 'Confirm',
};
