<script setup lang="ts">
import { onboardingGateway } from '@msl/user-gateway-sdk';
import { navigateTo } from '@mfl/framework';
import {
  WsButton,
  WsAlert,
  prompt,
  PromptResult,
} from '@mfl/common-components';
import { AccountSetupItem } from './account-setup-types';
import { sendAnalytics } from '../utils';
import { SetupStage } from './account-setup-consts';
import translations from './account-setup.strings';

const emit = defineEmits<{
  completeStage: [event: AccountSetupItem];
  expandOption: [event: AccountSetupItem];
}>();

type Props = {
  accountSetupData: AccountSetupItem[];
  userRole: string;
  emailPlatform?: string;
};

const props = withDefaults(defineProps<Props>(), {
  accountSetupData: () => [],
  userRole: '',
  emailPlatform: 'OTHER',
});

function eventAttributes(): Record<string, string> {
  return {
    tasks_completed: `${props.accountSetupData.filter((v) => v.isCompleted).length}`,
    total_tasks: `${props.accountSetupData.length}`,
    connector_type: props.emailPlatform,
    role: props.userRole,
  };
}

function learnMoreClick(option: AccountSetupItem) {
  sendAnalytics({
    eventName: option.linkEventName,
    eventAttributes: eventAttributes(),
  });
  navigateTo(option.learnMoreLink, { reload: true, newTab: true });
}

function actionClick(option: AccountSetupItem) {
  sendAnalytics({
    eventName: option.actionEventName,
    eventAttributes: eventAttributes(),
  });
  navigateTo(option.actionLink, { reload: true });
}

async function manualDeploymentClicked(option: AccountSetupItem) {
  if (option.disabled) return;
  sendAnalytics({
    eventName: 'setup_page_per_user_installation_modal_viewed',
    eventAttributes: eventAttributes(),
  });

  sendAnalytics({
    eventName: 'setup_page_connector_step_complete',
    eventAttributes: eventAttributes(),
  });

  const confirmResult = await prompt({
    aid: 'MANUAL_DEPLOYMENT_CONFIRM',
    header: translations.manualInstallationConfirmHeader,
    question: translations.manualInstallationConfirmDescr,
    primaryButtonText: translations.manualInstallationConfirmOk,
    secondaryButtonText: translations.manualInstallationConfirmCancel,
    width: '500px',
  });

  if (confirmResult === PromptResult.Primary) {
    try {
      await onboardingGateway.completeSetupStage({
        stepName: SetupStage.setConfiguration,
      });
      sendAnalytics({
        eventName: 'setup_page_per_user_installation_modal_confirmed',
        eventAttributes: eventAttributes(),
      });
      emit('completeStage', option);
    } catch (e) {
      console.error('update setup stage error.', e);
    }
  }

  if (confirmResult === PromptResult.Secondary) {
    sendAnalytics({
      eventName: 'setup_page_per_user_installation_modal_select_connectors',
      eventAttributes: eventAttributes(),
    });
    navigateTo(option.actionLink, { reload: true });
  }

  if (confirmResult === PromptResult.Dismiss) {
    sendAnalytics({
      eventName: 'setup_page_per_user_installation_modal_cancel',
      eventAttributes: eventAttributes(),
    });
  }
}
</script>
<template>
  <div
    v-for="(option, idx) in props.accountSetupData"
    :key="`setup-account-${idx}-${option.id}`"
    class="item"
  >
    <div
      class="item-collapsed"
      :aid="`SETUP_ACCOUNT_${option.id.toLocaleUpperCase()}_STEP`"
      @click="emit('expandOption', option)"
    >
      <div
        :class="{
          'item-status': !option.isCompleted,
          'item-status-done fa-solid fa-circle-check': option.isCompleted,
        }"
      ></div>
      <div class="text-md font-semibold item-title">{{ option.title }}</div>
      <div
        :class="[
          'fa-regular',
          {
            'fa-chevron-down': !option.isExpanded,
            'fa-chevron-up': option.isExpanded,
          },
        ]"
      ></div>
    </div>

    <div v-if="option.isExpanded" class="item-expanded">
      <div class="descr">
        <div>{{ option.descr }}</div>
        <WsButton
          v-if="option.learnMoreLink"
          :label="translations.learnMore"
          icon="fa-regular fa-arrow-up-right-from-square"
          variant="text"
          class="learn-more"
          :aid="`SETUP_ACCOUNT_LEARN_MORE_${option.id.toLocaleUpperCase()}`"
          size="sm"
          @click="learnMoreClick(option)"
        />
        <div v-if="option.actionLink" class="action-area">
          <WsButton
            :label="option.actionTitle"
            :disabled="option.disabled"
            size="sm"
            :aid="`SETUP_ACCOUNT_BTN_${option.id.toLocaleUpperCase()}`"
            @click="actionClick(option)"
          />
          <WsButton
            v-if="option.id === SetupStage.setConfiguration"
            :label="translations.manualInstallationBtn"
            :disabled="option.disabled"
            color="gray-500"
            variant="outlined"
            class="manual-installation-btn"
            aid="USE_MANUAL_INSTALLATION_BTN"
            size="sm"
            @click="manualDeploymentClicked(option)"
          />
        </div>

        <WsAlert
          v-if="option.disabled"
          type="info"
          variant="outlined"
          :closable="false"
          :open="true"
          :aid="`SETUP_ACCOUNT_BTN_ALERT_${option.id.toLocaleUpperCase()}`"
        >
          {{ translations.actionDisabledAlert }}
        </WsAlert>
      </div>
      <div v-if="option.imgScr">
        <img :src="option.imgScr" />
      </div>
    </div>
  </div>
</template>

<style>
.item {
  min-width: 745px;
  max-width: 1000px;
  border-radius: 6px;
  border: 1px solid rgb(var(--color-gray-200));
  margin-bottom: 10px;

  img {
    max-width: 200px;
  }
}

.item:hover {
  cursor: pointer;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.item-status {
  border: 1px solid rgb(var(--color-gray-300));
  height: 16px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 16px;
}

.item-status-done {
  color: rgb(var(--color-primary));
  font-size: 16px;
}

.item-title {
  text-align: left;
  width: 100%;
  padding: 0px 10px;
}

.item-collapsed {
  display: flex;
  justify-content: space-between;
  padding: 14px;
  align-items: center;
}

.item-expanded {
  display: flex;
  gap: 40px;
  padding-left: 40px;
  padding-bottom: 14px;
  justify-content: space-between;

  .descr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 0px;
    min-width: 500px;
    max-width: 650px;
    justify-content: flex-start;
  }

  .learn-more {
    padding: 14px 0px;
  }

  .action-area {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 14px;

    .manual-installation-btn {
      left: 10px;
    }
  }
}
</style>
