<script setup lang="ts">
import { IDialog, WsDialogSection } from '@mfl/common-components';
import { CalendlyDialogInput, CalendlyDialogOutput } from './calendly-dialog';
import { onMounted } from 'vue';
import { sendAnalytics } from '../../utils';

const { dialog } = defineProps<{
  dialog: IDialog<CalendlyDialogInput, CalendlyDialogOutput>;
}>();

onMounted(() => {
  sendAnalytics({ eventName: dialog.input.eventName });
  window.addEventListener(
    'message',
    function (e) {
      if (e.data.event == 'calendly.event_scheduled') {
        dialog.close({ demoScheduled: true });
      }
    },
    false
  );
});
</script>
<template>
  <WsDialogSection>
    <iframe
      class="calendly"
      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      :src="dialog.input.calendlyUrl"
      width="1000"
    >
    </iframe>
  </WsDialogSection>
</template>
<style>
.calendly {
  height: 600px;
  width: 1000px;
  border: none;
}
</style>
