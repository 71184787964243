<script setup lang="ts">
import onboardingStrings from '../../onboarding-wrapper.strings';
import radioButton from '../radio-button.vue';
import { RadioButtonOption } from '../../onboarding-wrapper-types';
import { OnboardingData } from '../../../types';
import { computed, onMounted, ref, watch } from 'vue';
import { EmailPlatform } from '@msl/user-gateway-sdk';
import { sendAnalytics } from '../../../utils';

const emit = defineEmits(['enableToContinue']);

const onboardingData = defineModel<OnboardingData>({
  required: true,
});

const emailPlatformOptions = ref<RadioButtonOption[]>([
  {
    value: EmailPlatform.MICROSOFT,
    label: onboardingStrings.emailPlatformOptionMicrosoft,
    className: 'microsoft',
  },
  {
    value: EmailPlatform.GOOGLE,
    label: onboardingStrings.emailPlatformOptionGoogle,
    className: 'google',
  },
  {
    value: EmailPlatform.OTHER,
    label: onboardingStrings.emailPlatformOptionOther,
  },
]);

const companyIndustryOptions = ref<RadioButtonOption[]>([
  {
    value: 'TECHNOLOGY',
    label: onboardingStrings.companyIndustryOptionTechnology,
  },
  {
    value: 'REAL_ESTATE',
    label: onboardingStrings.companyIndustryOptionRealEstate,
  },
  {
    value: 'HEALTH_CARE',
    label: onboardingStrings.companyIndustryOptionHealthcare,
  },
  {
    value: 'BUSINESS_SERVICES',
    label: onboardingStrings.companyIndustryOptionBusiness,
  },
  { value: 'FINANCE', label: onboardingStrings.companyIndustryOptionFinance },
  {
    value: 'NON_PROFIT',
    label: onboardingStrings.companyIndustryOptionNonProfit,
  },
  {
    value: 'MARKETING',
    label: onboardingStrings.companyIndustryOptionMarketing,
  },
  {
    value: 'EDUCATION',
    label: onboardingStrings.companyIndustryOptionEducation,
  },
  { value: 'LEGAL', label: onboardingStrings.companyIndustryOptionLegal },
  {
    value: 'ARCHITECTURE_CONSTRUCTION',
    label: onboardingStrings.companyIndustryOptionArchitecture,
  },
  { value: 'RETAIL', label: onboardingStrings.companyIndustryOptionRetail },
  {
    value: 'INDUSTRIAL',
    label: onboardingStrings.companyIndustryOptionIndustrial,
  },
  {
    value: 'HOSPITALITY',
    label: onboardingStrings.companyIndustryOptionHospitality,
  },
  {
    value: 'MEDIA_ENTERTAINMENT',
    label: onboardingStrings.companyIndustryOptionMedia,
  },
  {
    value: 'TRANSPORTATION',
    label: onboardingStrings.companyIndustryOptionTransportation,
  },
  {
    value: 'PUBLIC_GOVERNMENT',
    label: onboardingStrings.companyIndustryOptionPublic,
  },
  { value: 'OTHER', label: onboardingStrings.companyIndustryOptionOther },
]);

const isFormValid = computed(() => {
  return (
    onboardingData.value.workspaceInfo.emailPlatform !== undefined &&
    onboardingData.value.workspaceInfo.emailPlatform in EmailPlatform
  );
});

onMounted(() => {
  sendAnalytics({ eventName: 'onboarding_use_case_view' });
  emit('enableToContinue', isFormValid.value);
});

watch(isFormValid, (val) => {
  emit('enableToContinue', val);
});
</script>
<template>
  <div class="text-3xl font-semibold form-header">
    {{ onboardingStrings.nameYourWorkspaceFormEmailPlatformHeader }}
  </div>
  <radio-button
    v-model="onboardingData.workspaceInfo.emailPlatform"
    :options="emailPlatformOptions"
    aid="ONBOARDING_EMAIL_PLATFORM"
  ></radio-button>
  <div v-if="isFormValid">
    <div class="text-3xl font-semibold form-header">
      {{ onboardingStrings.whoAreCreatingSigForFormIndustryHeader }}
    </div>
    <radio-button
      v-model="onboardingData.accountTypeInfo.companyIndustry"
      :options="companyIndustryOptions"
      class="industry-options"
      aid="ONBOARDING_INDUSTRY"
    ></radio-button>
  </div>
</template>
<style lang="css">
.form-header {
  margin-top: 60px;
  margin-bottom: 24px;
}
.microsoft {
  background-image: url('../../../assets/onboarding-wrapper/microsoft.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  padding-left: 30px;
}

.google {
  background-image: url('../../../assets/onboarding-wrapper/google.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  padding-left: 30px;
}

@media screen and (min-width: 1347px) {
  .industry-options {
    width: 720px;
  }
}
</style>
