<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import onboardingStrings from '../../onboarding-wrapper.strings';
import { OnboardingData } from '../../../types';
import { EmailPlatform } from '@msl/user-gateway-sdk';
import { sendAnalytics } from '../../../utils';

const emit = defineEmits(['enableToContinue']);
const onboardingData = defineModel<OnboardingData>({
  required: true,
});

const demoBenefits = ref<string[]>([
  onboardingStrings.demoBenefitsGetAPersonalizedPlatform,
  onboardingStrings.demoBenefitsAnswerAnyQuestions,
  onboardingStrings.demoBenefitsSeeHowEasily,
  onboardingStrings.demoBenefitsChooseTheRightPlan,
]);

const isFormValid = computed(() => {
  return (
    onboardingData.value.calendlyUrl &&
    onboardingData.value.workspaceInfo.emailPlatform !== undefined &&
    onboardingData.value.workspaceInfo.emailPlatform in EmailPlatform
  );
});

onMounted(() => {
  sendAnalytics({ eventName: 'onboarding_email_provider_view' });
  emit('enableToContinue', isFormValid.value);
});

watch(isFormValid, (val) => {
  emit('enableToContinue', val);
});
</script>
<template>
  <div class="text-3xl font-semibold form-header">
    {{ onboardingStrings.skipTheLearningCurveHeader }}
  </div>
  <div class="benefits-container">
    <div
      v-for="(benefit, index) in demoBenefits"
      :key="index"
      class="benefits-list"
    >
      <i class="fa-solid fa-circle-check text-xl circle-check-blue"></i>
      <span class="text-xl benefits-text">{{ benefit }}</span>
    </div>
  </div>
</template>
<style lang="css" scoped>
.form-header {
  margin-top: 60px;
  margin-bottom: 40px;
}
.benefits-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.benefits-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.benefits-text {
  color: var(--Gray-500, #2d2e30);
}
.circle-check-blue {
  color: var(--Primary, #1e90fa);
}

@media screen and (min-width: 1347px) {
  .benefits-container {
    width: 720px;
  }
}
</style>
