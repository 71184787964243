<script setup lang="ts">
import { onBeforeMount, ref, computed, watch } from 'vue';
import { loadingDone, navigateTo } from '@mfl/framework';
import { auth4Sdks } from '@mfl/platform-shell';
import { WsButton, WsTooltip } from '@mfl/common-components';
import {
  AccountBasicInfoResponse,
  AccountType,
  onboardingGateway,
  OnboardingStatus,
  userGateway,
} from '@msl/user-gateway-sdk';
import strings from './onboarding-wrapper.strings';
import { OnboardingData } from '../types';
import onboardingStrings from './onboarding-wrapper.strings';
import { screenName, screens } from './onboarding-wrapper-consts';
import {
  setCalendlyUrl,
  sendAnalytics,
  errorNotification,
  setOnboardingData,
} from '../utils';
import { HeaderMode, headerMode } from '@mfe/core-header';
import { navigationBarMode, NavigationBarMode } from '@mfe/core-navigation';
import { openInviteEmailPreviewDialog } from '../components/calendly/calendly-dialog';

declare global {
  interface Window {
    dataLayer: Array<unknown>;
  }
}

// we do not restore the previous mode because
// we do reload the application after onboarding done
headerMode.value = HeaderMode.Hidden;
navigationBarMode.value = NavigationBarMode.Hidden;

const onboardingData = ref<OnboardingData>({
  userBasicInfo: {},
  accountTypeInfo: {},
  workspaceInfo: {},
  demoSession: undefined,
  demoScheduled: false,
  userEmail: '',
  userCountry: '',
  calendlyUrl: '',
  userRole: '',
});
const inProgress = ref<boolean>(false);
const currentStep = ref(-1);
const showOverlayMsg = ref<string>('');

onBeforeMount(async () => {
  try {
    await onboardingGateway.init(auth4Sdks());
    await initOnboardingData();
  } catch (e) {
    console.error('get onboarding status failed', e);
    errorNotification('ONBOARDING_ERROR_TOAST');
  } finally {
    loadingDone();
  }
});

const currentScreen = computed(() => {
  return screens[currentStep.value];
});

const isLastScreen = computed(() => {
  return currentStep.value == screens.length - 1;
});

const isFirstScreen = computed(() => {
  return currentStep.value == 0;
});

const isContinueBtnEnabled = ref<boolean>(false);

const scheduleDemoCompleted = computed(() => {
  return onboardingData.value.demoScheduled;
});

watch(scheduleDemoCompleted, async (val) => {
  if (val) {
    await formSubmit();
  }
});

function initCurrentStep(onboardingStatus: OnboardingStatus): void {
  if (!onboardingStatus?.sqlPage) {
    currentStep.value = 0;
  } else if (!onboardingStatus?.setupPage) {
    currentStep.value = 1;
  } else if (!onboardingStatus?.setWorkspace) {
    currentStep.value = 1;
  }
}

async function initOnboardingData(): Promise<void> {
  const resp = await onboardingGateway.getBasicInfo({});
  initCurrentStep(resp.onboardingStatus || {});
  setOnboardingData(onboardingData.value, resp, 'ws1_onboarding');
}

function enableContinue(val: boolean) {
  isContinueBtnEnabled.value = val;
}

async function getAccountInfo(): Promise<AccountBasicInfoResponse> {
  await userGateway.init(auth4Sdks());
  return await userGateway.accountBasicInfo({});
}

function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

async function completeOnboarding() {
  const accountData: AccountBasicInfoResponse = window.dataLayer
    ? await getAccountInfo()
    : {};
  await sleep(1000);
  showOverlayMsg.value = onboardingStrings.completeOnboardingTakingToWorkspace;
  await sleep(1000);
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'sign_up_complete',
      //'type': (onboardingData.value.accountTypeInfo.accountType === AccountType.TEAMS) ? 'TEAMS' : 'SOLO',
      type: 'TEAMS', // currently all the accounts we create from onboarding are TEAMS
      trial_days: accountData.remainDays,
      signup_company_size: onboardingData.value.userBasicInfo.companySize,
      target_audience:
        onboardingData.value.accountTypeInfo.accountType === AccountType.TEAMS
          ? 'my team'
          : 'myself',
      industry: onboardingData.value.accountTypeInfo.companyIndustry,
    });
    if (scheduleDemoCompleted.value) {
      window.dataLayer.push({
        event: 'demo_signup',
        company_size: onboardingData.value.userBasicInfo.companySize,
        industry: onboardingData.value.accountTypeInfo.companyIndustry,
        job_title: onboardingData.value.userBasicInfo.userTitle,
      });
    }
  }
  // before creating teams account domainId was personal-signature,
  // we should clean it up to be updated with a new domain ID
  sessionStorage.removeItem('domainId');
  sessionStorage.removeItem('domainKey');
  // for webapp - to render a new teams signature in editor instead of signature from localStorage
  localStorage.removeItem('prefilled_signature_data');
  // force reload to get the updated account data and the domain
  navigateTo('/v1/setup', { reload: true });
}

function nextStep() {
  if (isLastScreen.value) {
    return;
  }
  currentStep.value++;
}

function prevStep() {
  if (isFirstScreen.value) {
    return;
  }
  currentStep.value--;
}

async function formSubmit() {
  inProgress.value = true;

  const isEmailPlatformScreen =
    currentScreen.value.aid === screenName.ONBOARD_SCREEN_SELECT_EMAIL_PLATFORM;
  const isDemoOrSkipScreen =
    currentScreen.value.aid === screenName.ONBOARD_SCREEN_BOOK_A_DEMO_OR_SKIP;
  const isCalendlyUrlEmpty = !onboardingData.value.calendlyUrl;

  showOverlayMsg.value =
    isDemoOrSkipScreen || (isEmailPlatformScreen && isCalendlyUrlEmpty)
      ? onboardingStrings.completeOnboardingGettingReadyMsg
      : '';

  const resp = await currentScreen.value.action(onboardingData.value);
  await sendAnalytics(resp);

  // Sending analytics events as if the user visited in the last screen
  if (isEmailPlatformScreen && isCalendlyUrlEmpty) {
    sendAnalytics({ eventName: 'onboarding_email_provider_view' });

    //setting 1 sec difference between the previous event (different timestamp is needed)
    setTimeout(() => {
      sendAnalytics({
        eventName: 'onboarding_completed_user_skipped_demo',
        eventAttributes: {
          demo_scheduled: `${onboardingData.value.demoScheduled}`,
          user_region: '4', // region sign for users from countries which are not eligible for a demo
        },
      });
    }, 1000);
  }

  if (showOverlayMsg.value && Object.keys(resp).length) {
    await completeOnboarding();
  } else {
    inProgress.value = false;
    showOverlayMsg.value = '';

    if (Object.keys(resp).length) {
      setCalendlyUrl(onboardingData.value, 'ws1_onboarding');
      nextStep();
    } else {
      errorNotification('ONBOARDING_ERROR_TOAST');
    }
  }
}

const handleScheduleADemoBtn = async () => {
  if (!onboardingData.value.demoScheduled) {
    const res = await openInviteEmailPreviewDialog({
      calendlyUrl: onboardingData.value.calendlyUrl,
      eventName: 'schedule_demo_clicked',
    });
    onboardingData.value.demoScheduled = res?.demoScheduled || false;
  }
};
</script>

<template>
  <div v-if="currentScreen" class="onboarding-container">
    <div class="form">
      <div
        class="form-content"
        :class="{ 'last-screen': isLastScreen }"
        :aid="currentScreen.aid"
      >
        <img src="/onboarding-wrapper/logo.svg" />
        <component
          :is="currentScreen.form"
          v-model="onboardingData"
          @enable-to-continue="enableContinue"
        ></component>
      </div>
      <div
        class="footer"
        :class="{ 'justify-content-flex-end': isFirstScreen }"
      >
        <WsButton
          v-if="!isFirstScreen"
          color="gray-500"
          :label="strings.buttonBackLabel"
          size="lg"
          variant="text"
          icon="fa-regular fa-chevron-left"
          :aid="`SIGNUP_FLOW_BACK_BTN_${currentScreen.aid}`"
          @click="prevStep"
        />
        <div v-if="isLastScreen">
          <WsButton
            class="skip-btn"
            color="gray-400"
            :disabled="!isContinueBtnEnabled"
            :label="strings.buttonSkipLabel"
            size="lg"
            variant="text"
            :aid="`SIGNUP_FLOW_SKIP_BTN_${currentScreen.aid}`"
            @click="formSubmit"
            >{{ strings.buttonSkipLabel }}
            <WsTooltip
              v-if="!isContinueBtnEnabled"
              aid="SIGNUP_FLOW_CONTINUE_BTN_TOOLTIP"
            >
              {{ strings.buttonContinueDisabledTooltip }}
            </WsTooltip>
          </WsButton>
          <WsButton
            color="primary"
            :label="strings.buttonScheduleLabel"
            :disabled="!isContinueBtnEnabled"
            size="lg"
            :aid="`SIGNUP_FLOW_CONTINUE_BTN_${currentScreen.aid}`"
            @click="handleScheduleADemoBtn"
            >{{ strings.buttonScheduleLabel }}
            <WsTooltip
              v-if="!isContinueBtnEnabled"
              aid="SIGNUP_FLOW_CONTINUE_BTN_TOOLTIP"
            >
              {{ strings.buttonContinueDisabledTooltip }}
            </WsTooltip>
          </WsButton>
        </div>
        <div v-else>
          <WsButton
            color="primary"
            :label="strings.buttonContinueLabel"
            :disabled="!isContinueBtnEnabled"
            :loading="inProgress"
            size="lg"
            :aid="`SIGNUP_FLOW_CONTINUE_BTN_${currentScreen.aid}`"
            @click="formSubmit"
          >
            {{ strings.buttonContinueLabel }}
            <WsTooltip
              v-if="!isContinueBtnEnabled"
              aid="SIGNUP_FLOW_CONTINUE_BTN_TOOLTIP"
            >
              {{ strings.buttonContinueDisabledTooltip }}
            </WsTooltip>
          </WsButton>
        </div>
      </div>
    </div>
    <div class="margin">
      <component :is="currentScreen.svg"></component>
    </div>
  </div>
  <div v-if="showOverlayMsg" class="overlay">
    <div class="message">
      <div class="text-sm font-semibold">{{ showOverlayMsg }}</div>
      <div class="loader"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.onboarding-container {
  display: flex;
  position: fixed;
  height: 100vh;
  width: 100vw;
  justify-content: space-between;

  .margin {
    display: flex;
    width: 40vw;
    padding: 298px 70px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background: linear-gradient(228deg, #ea3fb8 -0.01%, #1e90fa 116.52%);
  }

  .form {
    display: flex;
    width: 60vw;
    padding: 60px;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    background: #fff;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
  }

  .form-content {
    max-width: 600px;
    &.last-screen {
      max-width: 720px;
    }
  }

  .footer {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
  }

  .justify-content-flex-end {
    justify-content: flex-end;
  }
  .skip-btn {
    padding: 0 14px 0 0;
  }
}

.overlay {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--Overlay, rgba(0, 0, 0, 0.7));

  .message {
    background-color: #fff;
    border-radius: 8px;
    padding: 6px 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: fit-content;
  }

  .loader {
    display: block;
    visibility: hidden;
    width: 14px;
    aspect-ratio: 1;
    background: var(--Gray-500, #2d2e30);
    --b: 2px;
    --a: 135deg;
    border-radius: 50%;
    padding: var(--b);
    --_g: /var(--b) var(--b) no-repeat radial-gradient(50% 50%, #000 97%, #0000);
    mask:
      top var(--_g),
      calc(50% + 50% * sin(var(--a))) calc(50% - 50% * cos(var(--a))) var(--_g),
      linear-gradient(#0000 0 0) content-box intersect,
      conic-gradient(#000 var(--a), #0000 0);
    background-repeat: no-repeat;
    animation: spin-me 1s infinite 500ms linear;
  }
}

@media screen and (max-width: 1000px) {
  .margin {
    display: none !important;
  }
  .form {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    background-color: yellowgreen;
  }
}
</style>
