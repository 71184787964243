import { AccountSetupItem } from './account-setup-types';
import {
  currentUser,
  WEBAPP_BASE_URL,
  PermissionTypes,
} from '@mfl/platform-shell';
import accountSetupStrings from './account-setup.strings';
import { resolveImagePath } from '../utils';

export enum SetupStage {
  setConfiguration = 'setConfiguration',
  signatureUpdate = 'signatureUpdate',
  activateEmployee = 'activateEmployee',
  addEmployee = 'addEmployee',
}

export const setupAccountSteps: AccountSetupItem[] = [
  {
    id: 'signatureUpdate',
    title: accountSetupStrings.signatureUpdateTitle,
    descr: accountSetupStrings.signatureUpdateDescr,
    imgScr: resolveImagePath('account-setup/design-signature.png'),
    learnMoreLink:
      'https://support.wisestamp.com/hc/en-us/articles/12170554703517-Overview-of-the-Signature-Editor',
    actionLink: `${WEBAPP_BASE_URL}/editor`,
    actionTitle: accountSetupStrings.signatureUpdateActionBtn,
    disabled: !currentUser.isAllowedTo(PermissionTypes.editSignature),
    actionEventName: 'setup_page_edit template_clicked',
    linkEventName: 'setup_page_learn_more_about_templates',
  },
  {
    id: 'addEmployee',
    title: accountSetupStrings.addEmployeeTitle,
    descr: accountSetupStrings.addEmployeeDescr,
    imgScr: resolveImagePath('account-setup/add-employee.svg'),
    learnMoreLink:
      'https://support.wisestamp.com/hc/en-us/articles/13509289151261-Add-and-Import-Employees',
    actionLink: `${WEBAPP_BASE_URL}/employees?addEmployee`,
    actionTitle: accountSetupStrings.addEmployeeActionBtn,
    disabled: !currentUser.isAllowedTo(PermissionTypes.manageEmployee),
    actionEventName: 'setup_page_add_employees_clicked',
    linkEventName: 'setup_page_learn_more_about_add_employees',
  },
  {
    id: 'setConfiguration',
    title: accountSetupStrings.setConfigurationTitle,
    descr: accountSetupStrings.setConfigurationDescr,
    imgScr: resolveImagePath('account-setup/set-configuration.png'),
    learnMoreLink:
      'https://support.wisestamp.com/hc/en-us/articles/14792851691165-Connectors-Comparison',
    actionLink: `${WEBAPP_BASE_URL}/connectors`,
    actionTitle: accountSetupStrings.setConfigurationActionBtn,
    disabled: !currentUser.isAllowedTo(PermissionTypes.manageDomain),
    actionEventName: 'setup_page_add_connector_clicked',
    linkEventName: 'setup_page_learn_more_about_connectors',
  },
  {
    id: 'activateEmployee',
    title: accountSetupStrings.activateEmployeeTitle,
    descr: accountSetupStrings.activateEmployeeDescr,
    imgScr: resolveImagePath('account-setup/activate-employee.png'),
    learnMoreLink:
      'https://support.wisestamp.com/hc/en-us/articles/13693606400285-Activate-Employee-Signatures',
    actionLink: `${WEBAPP_BASE_URL}/employees?activateEmployee`,
    actionTitle: accountSetupStrings.activateEmployeeActionBtn,
    disabled: !currentUser.isAllowedTo(PermissionTypes.manageEmployee),
    actionEventName: 'setup_page_activate_employees_clicked',
    linkEventName: 'setup_page_learn_more_about_activating',
  },
];
