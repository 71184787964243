import tellUsAboutYouMargin from './components/margins/tell-us-about-you.vue';
import bookADemoOrSkipMargin from './components/margins/book-a-demo-or-skip.vue';
import selectEmailPlatformMargin from './components/margins/select-email-platform.vue';
import tellUsAboutYouForm from './components/forms/tell-us-about-you.vue';
import bookADemoOrSkip from './components/forms/book-a-demo-or-skip.vue';
import selectEmailPlatformForm from './components/forms/select-email-platform.vue';
import {
  selectEmailPlatformFormSubmit,
  tellUsAboutYouFormSubmit,
  bookADemoOrSkipFormSubmit,
} from './onboarding-wrapper-form-actions';

export enum screenName {
  ONBOARD_SCREEN_TELL_US_ABOUT_YOU = 'ONBOARD_SCREEN_TELL_US_ABOUT_YOU',
  ONBOARD_SCREEN_SELECT_EMAIL_PLATFORM = 'ONBOARD_SCREEN_SELECT_EMAIL_PLATFORM',
  ONBOARD_SCREEN_BOOK_A_DEMO_OR_SKIP = 'ONBOARD_SCREEN_BOOK_A_DEMO_OR_SKIP',
}

export enum bookDemoSession {
  NO = 0,
  YES = 1,
}

export const screens = [
  {
    aid: screenName.ONBOARD_SCREEN_TELL_US_ABOUT_YOU,
    svg: tellUsAboutYouMargin,
    form: tellUsAboutYouForm,
    action: tellUsAboutYouFormSubmit,
  },
  {
    aid: screenName.ONBOARD_SCREEN_SELECT_EMAIL_PLATFORM,
    svg: selectEmailPlatformMargin,
    form: selectEmailPlatformForm,
    action: selectEmailPlatformFormSubmit,
  },
  {
    aid: screenName.ONBOARD_SCREEN_BOOK_A_DEMO_OR_SKIP,
    svg: bookADemoOrSkipMargin,
    form: bookADemoOrSkip,
    action: bookADemoOrSkipFormSubmit,
  },
];
